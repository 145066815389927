import {Component, OnInit} from '@angular/core';
import {Cookie} from '@ngx-toolkit/cookie';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Cookie('hcw')
  hideCookieWarning: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
