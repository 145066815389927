import {Component, OnInit} from '@angular/core';

declare let ga: Function;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  gotoInsta() {
    ga('send', 'event', 'Links', 'Insta', 'ContactPage');
    window.open('https://www.instagram.com/explore/tags/fleurtjeweekend/', '_blank');
  }

  download() {
    ga('send', 'event', 'Download', 'Privacy-verklaring', 'ContactPage');
  }
}
