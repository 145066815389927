import { Component, OnInit } from '@angular/core';
declare let ga: Function;

@Component({
  selector: 'app-about',
  templateUrl: './boeketten.component.html',
  styleUrls: ['./boeketten.component.scss']
})
export class BoekettenComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  gotoInsta() {
    ga('send', 'event', 'Links', 'Insta', 'AboutPage');
    window.open('https://www.instagram.com/fleurtjeweekend/', '_blank');
  }
}
