import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MenuComponent} from './menu/menu.component';
import {HeaderComponent} from './header/header.component';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {ZoWerktHetComponent} from './zo-werkt-het/zo-werkt-het.component';
import {FolderComponent} from './folder/folder.component';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {CookiesComponent} from './cookies/cookies.component';
import {CookieBannerComponent} from './cookie-banner/cookie-banner.component';
import {CookieModule} from '@ngx-toolkit/cookie';
import {BoekettenComponent} from './boeketten/boeketten.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ZoWerktHetComponent,
    FolderComponent,
    CookiesComponent,
    CookieBannerComponent,
    BoekettenComponent

  ],
  imports: [
    CookieModule.forRoot(),
    AngularFontAwesomeModule,
    BrowserModule,
    NgbModule.forRoot(),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
