import { Component, OnInit } from '@angular/core';

declare let ga: Function;

@Component({
  selector: 'app-zo-werkt-het',
  templateUrl: './zo-werkt-het.component.html',
  styleUrls: ['./zo-werkt-het.component.scss']
})
export class ZoWerktHetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  gotoLoket() {
    ga('send', 'event', 'Links', 'Insta', 'LoketPage');
    window.open('https://bloemetje.loket.nl/#starten', '_blank');
  }

}
