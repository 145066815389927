import { Component, OnInit } from '@angular/core';
import {Cookie, CookieService} from '@ngx-toolkit/cookie';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {
  @Cookie('hcw')
  hideCookieWarning: boolean;

  constructor() { }

  ngOnInit() {
  }


  onHideCookieWarning() {
    this.hideCookieWarning = true;
  }
}
