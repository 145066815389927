import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {ZoWerktHetComponent} from './zo-werkt-het/zo-werkt-het.component';
import {FolderComponent} from './folder/folder.component';
import {CookiesComponent} from './cookies/cookies.component';
import {BoekettenComponent} from './boeketten/boeketten.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'zo-werkt-het',
    component: ZoWerktHetComponent
  },
  {
    path: 'boeketten',
    component: BoekettenComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'folder',
    component: FolderComponent
  },
  {
    path: 'cookies',
    component: CookiesComponent
  },
  {path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
